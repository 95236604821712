// module.exportを使ってhello関数を定義する。
export const hello = (message) => {

  document.addEventListener('DOMContentLoaded', function(){
    //document.body.innerHTML = `${message}`;
    console.log("testを出力しました");
}, false);

};

//アコーディオン開閉
$('.js-accordion-button').removeClass('is-open');
$('.js-accordion-body').removeClass('is-open');
$('.js-accordion-body').hide();
$('.js-accordion-button').on('click',function(){
  $(this).parent().find('.js-accordion-body').slideToggle();
  $(this).parent().find('.js-accordion-body').toggleClass('is-open');
  $(this).toggleClass('is-open');
});


//ローディング
jQuery(function ($) {
	let loadStatus = 0;
	let img_num = $('img').length;

	$('img').each(function() {
		let src = $(this).attr('src');
		$('<img>').attr('src', src).on('load',function() {
			loadStatus++;
			let percentage = Math.floor(loadStatus / img_num * 100);
			$('.loader__img').css({
				'width': percentage + '%'
			});
		});
	});
});

$(window).on('load',function () {
	$('.loader__img').css({
		'width': '100%'
	});
	setTimeout(function() {
		$('.loader').fadeOut();
	}, 1500);
});

let oldH = 0;
function setFillHeight() {
	const vh = window.innerHeight * 0.01 ;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
	oldH = window.innerHeight;
}

window.addEventListener('load', function() {
  	setFillHeight();
});
window.addEventListener('resize', function() {
	let h = window.innerHeight;
	if ( oldH != h ) {
		setFillHeight();
	}
});

$(window).on('load',function () {
  $(".sec06__youtube_wrap").slick({
    dots: false, // ドットインジケーターの表示
    infinite: true, // スライドのループを有効にするか
    slidesToShow: 3, // 表示するスライド数を設定
    slidesToScroll: 1, // スクロールするスライド数を設定
		centerMode: true,
		centerPadding: 0, // 中央のスライドの両サイドのパディングを設定
		responsive: [
			{
				breakpoint: 751, // ブレイクポイントを751pxに設定
				settings: {
					slidesToShow: 1, // ブレイクポイント以下では表示するスライド数を1に設定
				}
			}
		],
		prevArrow: '<button type="button" class="slick-prev"><img src="2024/img/slide_prev.png" alt="Previous"></button>',
		nextArrow: '<button type="button" class="slick-next"><img src="2024/img/slide_next.png" alt="Next"></button>',
  });
});
